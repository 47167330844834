<template>
  <div>
    <q-form ref="editForm">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <!-- 응급상황 대응 정보 -->
          <c-card title="응급상황 대응 정보" class="cardClassDetailForm">
            <template slot="card-button">
              <q-btn-group outline >
                      <c-btn v-if="editable && popupParam.emeConcactId" label="삭제" icon="delete_forever" @btnClicked="removeData" />
                      <c-btn
                  v-if="editable"
                  :url="saveUrl"
                  :isSubmit="isSave"
                  :param="data"
                  :mappingType="mappingType"
                  label="저장"
                  icon="save"
                  @beforeAction="saveData"
                  @btnCallback="saveCallback" />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-3col-lg-3 col-xl-3">
                <c-plant required  type="edit" name="plantCd" v-model="data.plantCd" :editable="editable"/>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <!-- 연락망 -->
                <c-table
                  ref="grid"
                  title="연락망"
                  tableId="grid"
                  :columnSetting="false"
                  :isFullScreen="false"
                  :usePaging="false"
                  :filtering="false"
                  :isExcelDown="false"
                  :columns="grid.columns"
                  :data="data.networks"
                  gridHeight="260px"
                  selection="multiple"
                  rowKey="emeConcactNetworkId"
                  :editable="editable"
                >
                  <!-- 버튼 영역 -->
                  <template slot="table-button">
                    <q-btn-group outline >
                                  <c-btn label="추가" icon="add" @btnClicked="addrow" v-if="editable" />
                                  <c-btn label="삭제" icon="remove" @btnClicked="removeRow" v-if="editable" />
                    </q-btn-group>
                  </template>
                </c-table>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <!-- 초기 대응법 -->
                <c-textarea
                  :editable="editable"
                  type="editor"
                  label="초기 대응법"
                  name="remark"
                  :editheight="20"
                  v-model="data.remark">
                </c-textarea>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style="margin-top:20px;">
                <!-- 첨부파일 -->
                <c-upload 
                  :attachInfo="attachInfo"
                  :editable="editable"
                  label="첨부파일">
                </c-upload>
              </div>
            </template>
          </c-card>
        </div>
      </div>
    </q-form>
  </div>
</template>

<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'eme-contact-detail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        emeConcactId: '',
      }),
    },
  },
  data() {
    return {
      saveUrl: transactionConfig.sop.eme.contact.insert.url,
      mappingType: 'POST',
      insertUrl: '',
      updateUrl: '',
      deleteUrl: '',
      editable: true,
      isSave: false,
      qListUrl: '',
      qSaveUrl: '',
      qDeleteUrl: '',
      data: {
        plantCd: null,
        emeConcactId: '', 
        remark: '',
        networks: []
      },
      grid: {
        columns: [],
      },
      updateMode: false,
      attachInfo: {
        isSubmit: '',
        taskClassCd: 'EME_CONTACT',
        taskKey: '',
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      
      this.detailUrl = selectConfig.sop.eme.contact.get.url;
      this.checkUrl = selectConfig.sop.eme.contact.check.url;
      this.insertUrl = transactionConfig.sop.eme.contact.insert.url;
      this.updateUrl = transactionConfig.sop.eme.contact.update.url;
      this.deleteUrl = transactionConfig.sop.eme.contact.delete.url;
      this.qListUrl = selectConfig.sop.eme.contact.networks.url;
      this.qSaveUrl = transactionConfig.sop.eme.network.save.url;
      this.qDeleteUrl = transactionConfig.sop.eme.network.delete.url;
      this.getDetail();
    },
    getDetail() {
      this.$comm.getComboItems('EME_CONTACT_TYPE_CD').then(_result => {
        this.grid.columns = [];
        this.grid.columns.push(
          {
            required: true,
            name: 'emeConcactTypeCd',
            field: 'emeConcactTypeCd',
            // 연락처 유형
            label: '연락처 유형',
            align: 'center',
            type: 'select',
            comboItems: _result,
            style: 'width:25%',
            sortable: false,
          },
          {
            required: true,
            name: 'contactNm',
            field: 'contactNm',
            // 연락처 명
            label: '연락처 명',
            align: 'center',
            type: 'text',
            style: 'width:40%',
            sortable: false,
          },
          {
            required: true,
            name: 'contactNo',
            field: 'contactNo',
            // 연락처
            label: '연락처',
            align: 'center',
            type: 'text',
            sortable: false,
          },
        )

        if (this.popupParam.emeConcactId) {
          this.$http.url = this.detailUrl;
          this.$http.param = {emeConcactId: this.popupParam.emeConcactId};
          this.$http.type = 'GET';
          this.$http.request((_result) => {
            this.data = _result.data;
            this.updateMode = true;
            this.$emit('setRegInfo',this.data)
            this.$set(this.attachInfo, 'taskKey', this.popupParam.emeConcactId);
          },);
        }
      });
    },
    saveData() {
      if (this.popupParam.emeConcactId) {
        this.saveUrl = this.updateUrl;
        this.mappingType = 'PUT';
      } else {
        this.saveUrl = this.insertUrl;
        this.mappingType = 'POST';
      }
      this.$refs['editForm'].validate().then(_result => {
        if (_result && this.$comm.validTable(this.grid.columns, this.data.networks)) {
          this.$http.url = this.$format(this.checkUrl, this.data.plantCd);
          this.$http.type = 'GET';
          this.$http.request((_result) => {
            if (this.updateMode || _result.data == 0) {
              window.getApp.$emit('CONFIRM', {
                title: '확인',
                message: '저장하시겠습니까?',
                
                type: 'info', // success / info / warning / error
                // 확인 callback 함수
                confirmCallback: () => {
                  this.data.regUserId = this.$store.getters.user.userId;
                  this.data.chgUserId = this.$store.getters.user.userId;
                  this.isSave = !this.isSave;
                },
                // 취소 callback 함수
                cancelCallback: () => {
                },
              });
            } else {
              window.getApp.$emit('ALERT', {
                title: '안내',
                message:
                '동일한 사업장에 데이터가 존재합니다.', // 동일한 사업장에 데이터가 존재합니다.
                type: 'warning', // success / info / warning / error
              });
              return;
            }
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.popupParam.emeConcactId = result.data
      this.getDetail();
    },
    removeData() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '삭제하시겠습니까?',
        
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.popupParam.emeConcactId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$emit('closePopup');
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    addrow() {
      this.data.networks.push({
        editFlag: 'C',
        emeConcactId: this.popupParam.emeConcactId,
        emeConcactNetworkId: uid(), 
        emeConcactTypeCd: null,
        contactNm : '',
        contactNo: '', 
      })
    },
    removeRow() {
      let selectData = this.$refs['grid'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: '선택된 항목이 없습니다.',
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '삭제하시겠습니까?',
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.qDeleteUrl;
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            };
            this.$http.request(() => {
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              this.$_.forEach(selectData, item => {
                this.data.networks = this.$_.reject(this.data.networks, item);
              })
              this.$refs['grid'].$refs['compo-table'].clearSelection();
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
  }
};
</script>
